let timer;

let compareDate = new Date(2018, 8, 9, 5, 0, 0);

timer = setInterval(function () {
    timeBetweenDates(compareDate);
}, 1000);

function timeBetweenDates(toDate) {
    let dateEntered = toDate;
    let now = new Date();
    let difference = now.getTime() - dateEntered.getTime();

    if (difference <= 0) {
        // Timer done
        clearInterval(timer);

    } else {
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let months = Math.floor(days / 30);
        let years = Math.floor(months / 12);

        //hours %= 24;
        //minutes %= 60;
        //seconds %= 60;


        document.querySelector("#seconds").innerHTML = new Intl.NumberFormat().format(seconds);
        document.querySelector("#days").innerHTML = Intl.NumberFormat().format(days);
        document.querySelector("#months").innerHTML = Intl.NumberFormat().format(months);
        document.querySelector("#years").innerHTML = Intl.NumberFormat().format(years);
        document.querySelector("#hours").innerHTML = Intl.NumberFormat().format(hours);
        document.querySelector("#minutes").innerHTML = Intl.NumberFormat().format(minutes);
    }
}

var requestAnimationFrame = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame;

const circlesGroup = document.querySelector('.heart__fill-circles-group');
const circlesElems = document.querySelectorAll('.heart__mask-circle');
const circles = [];
const groupAnimatedClass = 'heart__fill-circles-group--animated';

const pathLength = 350;
const maxSteps = 80;
const maxR = 50;
const rStep = maxR / maxSteps;

const Circle = function ({ elem, direction, delay }) {
    this.elem = elem;
    this.direction = direction;
    this.delay = delay;

    this.resetValues();
};

Circle.prototype.resetValues = function () {
    const angleDeg = Math.round(Math.random() * 360);
    const angleRad = angleDeg * Math.PI / 180;

    this.x = pathLength * Math.sin(angleRad); // c * sin(B)
    this.y = pathLength * Math.cos(angleRad); // c * cos(B)
    this.xStep = this.x / maxSteps;
    this.yStep = this.y / maxSteps;
    this.currentStep = maxSteps / 4.5;
};

Circle.prototype.changeProp = function () {
    let x = this.xStep * this.currentStep;
    let y = this.yStep * this.currentStep;

    if (this.direction === 1) {
        // right top
        y *= -1;
    }
    else if (this.direction === 2) {
        // right bottom
        // make nothing
    }
    else if (this.direction === 3) {
        // left bottom
        x *= -1;
    }
    else if (this.direction === 4) {
        // left top
        x *= -1;
        y *= -1;
    }

    this.currentStep++;

    this.elem.setAttribute('transform', `translate(${x}, ${y})`);
    this.elem.setAttribute('r', (Math.abs(maxSteps - this.currentStep)) * rStep);

    if (this.currentStep >= maxSteps) {
        this.resetValues();
    }

    requestAnimationFrame(() => {
        this.changeProp();
    });
};

function setInitial() {
    circles.forEach(circle => {
        circle.elem.setAttribute('transform', `translate(0, 0)`);
        circle.elem.setAttribute('r', maxR);
    })
};

const runAnimation = () => {
    circles.forEach((circle, i) => {
        setTimeout(() => {
            circle.resetValues();
            circle.changeProp();
        }, circle.delay);
    })
};

const prepareCircles = () => {
    circlesElems.forEach((elem, i) => {
        const direction = Math.floor(Math.random() * 4) + 1;
        const circle = new Circle({
            elem,
            direction,
            delay: i * 100
        });

        circles.push(circle);
    });
}

// ------------------------------

prepareCircles();
requestAnimationFrame(runAnimation);
